import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private http: HttpClient) { }

  saveQuote(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/quote/save`, formData, { observe: 'events', reportProgress: true });
  }

  getList(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/quote/list`, {});
  }

  getAvailableQuote(formData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/quote/available`, formData);
  }

  getQuote(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/quote/getquote`, formData);
  }

  deleteQuote(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/quote/delete`, formData);
  }

  sendMail(formData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/quote/mailing`, formData);
  }

  // old quote
  saveOldQuote(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/oldquote/save`, formData, { observe: 'events', reportProgress: true });
  }

  getOldList(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/oldquote/list`, {});
  }

  getOldAvailableQuote(formData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/oldquote/available`, formData);
  }

  getOldQuote(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/oldquote/getquote`, formData);
  }

  deleteOldQuote(formData: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/oldquote/delete`, formData);
  }
}
